let year = new Date().getFullYear();
export default {
  wsHost:
    process.env.NODE_ENV === "production"
      ? "wss://api-psb.nuruljadid.net/ws"
      // : "ws://127.0.0.1:4000",
      : "wss://api-psb.nuruljadid.net/ws",

  baseUrlApi:
    process.env.NODE_ENV === "production"
      ? "https://api-psb.nuruljadid.net"
      // : "http://127.0.0.1:4000/api/v1",
      : "https://api-psb.nuruljadid.net",

  recaptchaKey:
    process.env.NODE_ENV === "production"
      ? "6Ld9IZQUAAAAAOpheb1ZR8PW5nsp1EhhxByr1R_8"
      : "6Ld9IZQUAAAAAOpheb1ZR8PW5nsp1EhhxByr1R_8",
  app: {
    // default configuration
    tanggalLahirRange: [year - 100, year - 1],
  },
};
